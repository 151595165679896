<template>
  <div class="container"></div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { SetPvAndUv } from '../mixins/PvAndUv'
import { api } from '@/api/index'
export default {
  name: 'Index',
  mixins: [SetPvAndUv],
  data() {
    return {
      ticket: '',
      ticketId: '', //微信环境
      userId: '',
      userName: '',
      token: '',
      userType: '',
      sp: '',
    }
  },
  mounted() {
    if (!this.judgeEnvWechat()) {
      ZWJSBridge.showPreloader({
        text: '请稍等..',
        showIcon: true,
      })
    }
    //二次回退
    if (this.judgeEnv()) {
      if (this.$store.state.token) {
        this.$store.state.token = ''
        my.navigateBack()
      }
    } else {
      if (this.$store.state.token) {
        this.$store.state.token = ''
        ZWJSBridge.close()
      }
    }
    console.log('首页进入' + window.location.href)
    if (this.judgeEnvWechat()) {
      this.loginWx()
    } else {
      this.loginZlb()
    }
  },
  methods: {
    loginZlb() {
      this.ticket = this.getQuery('ticket') ? this.getQuery('ticket') : ''
      // console.log("ticket", this.ticket);
      this.sp = this.getQuery('sp') ? this.getQuery('sp') : '' //扫码跳转的地址
      this.token = this.getQuery('token') ? this.getQuery('token') : ''
      // console.log("token", this.token);
      //兼容zfb zfb有时会调用不了ZWJSBridge
      if (this.judgeEnv()) {
        //个人登录
        if (this.ticket) {
          this.$store.dispatch('changeZlbUserType', 1).then((res) => {
            //个人单点登录成功
            this.getTokenByPersonal()
          })
        } else {
          window.location.replace(
            'https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&scope=1&servicecode=BCDSGA_db0988b5e8dc97700c8205be915fe959',
          )
        }
      } else {
        ZWJSBridge.getUserType()
          .then((result) => {
            console.log('result', result)
            this.$store
              .dispatch('changeZlbUserType', result.userType)
              .then((res) => {
                if (result.userType == 2) {
                  if (!this.token) {
                    window.location.replace(
                      'https://esso.zjzwfw.gov.cn/opensso/spsaehandler/metaAlias/sp?spappurl=https://zyt.dev.zhijiangfilm.com/zyt/zj-cultural-film/zlb/mobile/legalPersonSingleSignOn',
                    )
                  } else {
                    //法人单点登录成功
                    this.userId = this.getQuery('userid')
                    this.userName = this.getQuery('userName')
                    this.userType = this.getQuery('userType')
                    this.$store.commit('changeToken', this.token)
                    this.$store.commit('changeZlbUserId', this.userId)
                    this.$store.commit('changeUserName', this.userName)
                    this.$store.commit('changeUserType', this.userType)

                    api
                      .addUniqueVisitor({
                        dataSource: 1,
                        requestSource: 1,
                      })
                      .then((res) => {})
                    this.getLegalPersonByUserId()
                  }
                } else {
                  if (!this.ticket) {
                    if (this.judgeEnv()) {
                      window.location.replace(
                        // "https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&scope=1&servicecode=BCDSGA_db0988b5e8dc97700c8205be915fe959&redirectUrl=https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2002207363/reserved/index.html"
                        'https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&scope=1&servicecode=BCDSGA_db0988b5e8dc97700c8205be915fe959',
                      )
                    } else {
                      window.location.replace(
                        'https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=BCDSGA_db0988b5e8dc97700c8205be915fe959&redirectUrl=https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2002207363/lastTest/index.html',
                        // "https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=BCDSGA_db0988b5e8dc97700c8205be915fe959&redirectUrl=https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2002207363/reserved/index.html"
                      )
                    }
                  } else {
                    //个人单点登录成功
                    this.getTokenByPersonal()
                  }
                }
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    loginWx() {
      this.ticketId = this.getQuery('ticketId') ? this.getQuery('ticketId') : ''
      if (ZWJSBridge.ssoTicket) {
        ZWJSBridge.ssoTicket({}).then((ssoFlag) => {
          if (ssoFlag && ssoFlag.result === true) {
            //使用 IRS“浙里办”单点登录组件
            if (ssoFlag.ticketId) {
              //个人获取token
              this.getTokenByPersonalWX()
            } else {
              //当“浙里办”单点登录失败或登录态失效时调用 ZWJSBridge.openLink 方法重新获取 ticketId。
              ZWJSBridge.openLink({ type: 'reload' }).then((res) => {
                res.ticketId
              })
            }
          } else {
            //个人登录
            this.loginZlb()
          }
        })
      } else {
        //法人登录
        this.loginZlb()
      }
    },
    //判断app还是小程序
    judgeEnv() {
      const sUserAgent = window.navigator.userAgent.toLowerCase()
      const bIsAlipayMini =
        sUserAgent.indexOf('miniprogram') > -1 &&
        sUserAgent.indexOf('alipay') > -1
      if (bIsAlipayMini) {
        console.log('支付宝小程序')
        return true
      } else {
        console.log('app')
        return false
      }
    },
    //判断是否是微信环境
    judgeEnvWechat() {
      const isWechat =
        window.navigator.userAgent.toLowerCase().includes('miniprogram/wx') ||
        window.__wxjs_environment === 'miniprogram'
      if (isWechat) {
        return true
      } else {
        return false
      }
    },
    //获取地址上的参数
    getQuery(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    //个人获取token
    getTokenByPersonal() {
      this.$axios({
        apiUrl: '/zlb/mobile/singleSignOn',
        url: 'mgop.wasu.zyt.zlb0mobile0singleSignOn',
        method: 'GET',
        data: {
          ticket: this.ticket,
        },
      }).then((res) => {
        if (res.code == 200) {
          console.log('个人token成功' + JSON.stringify(res))

          this.$store.commit('changeToken', res.result.token)
          this.$store.commit('changeZlbUserId', res.result.userid)
          this.$store.commit('changeUserType', res.result.userType)
          this.$store.commit('changeUserName', res.result.username)
          this.$store.commit('changeUserInfo', res.result)
          api
            .addUniqueVisitor({
              dataSource: 1,
              requestSource: 1,
            })
            .then((res) => {})
          ZWJSBridge.hidePreloader()
          this.$router.push({
            path: this.sp ? '/' + this.sp : '/home',
          })
        } else if (res.code == 500) {
          Toast(res.message)
        }
      })
    },
    //个人获取token微信环境
    getTokenByPersonalWX() {
      this.$axios({
        apiUrl: '/zlb/wechat/login',
        url: 'mgop.wasu.zyt.zlb0wechat0login',
        method: 'GET',
        data: {
          ticketId: this.ticketId,
        },
      }).then((res) => {
        if (res.code == 200) {
          console.log('wx个人token成功' + JSON.stringify(res))
          this.$store.commit('changeToken', res.result.token)
          this.$store.commit('changeZlbUserId', res.result.userid)
          this.$store.commit('changeUserType', res.result.userType)
          this.$store.commit('changeUserName', res.result.username)
          api
            .addUniqueVisitor({
              dataSource: 1,
              requestSource: 1,
            })
            .then((res) => {})
          ZWJSBridge.hidePreloader()
          this.$router.push({
            path: this.sp ? '/' + this.sp : '/home',
          })
        } else {
          Toast('身份验证失败,请登录个人用户账号')
          setTimeout(() => {
            ZWJSBridge.close()
          }, 2000)
        }
      })
    },
    //获取法人信息
    getLegalPersonByUserId() {
      this.$axios({
        apiUrl: '/zlb/getZlbUserInfo',
        url: 'mgop.wasu.zyt.zlb0getZlbUserInfo',
        method: 'GET',
        data: {
          userid: this.userId,
        },
      }).then((res) => {
        res.result.mobile = res.result.username //解决法人用户无手机号，后台用户名即为
        console.log('法人信息' + JSON.stringify(res))
        ZWJSBridge.hidePreloader()
        this.$store.dispatch('changeUserInfo', res.result).then((res) => {
          this.$router.push({
            path: this.sp ? '/' + this.sp : '/home',
          })
        })
      })
    },
  },
}
</script>

<style scoped lang="less"></style>
